.display-text-field{
    border: 1px solid #c5c5c5;
    margin-top: 16px;
    margin-bottom: 8px;
    padding: 15px;
    border-radius: 4px;
    min-height: 35px;
    word-break: break-word;
}
.display-text-field-centered{
    border: 1px solid #c5c5c5;
    margin-top: 16px;
    margin-bottom: 8px;
    padding: 15px;
    border-radius: 4px;
    min-height: 35px;
    text-align: center;
    word-break: break-word;
}

.legend-text-field{
    color: #8c8c8c;
    font-size: 12px;
}