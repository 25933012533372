.line {
    opacity: 1;
    background: linear-gradient(to right, #a0cf63 0%, #a0cf63 20%, #a0cf63 40%, #f6c343 65%, #ea3323 80%);

    .chip {
        .score {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            color: white;
            position: relative;
            top: 2px;
            left: 0px;
        }
    }
}
